import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';
@Injectable({
  providedIn: 'root'
})
export class CommuneService {

  api = `${environment.api}/api/communes`;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  getAllCommunes(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(this.api, httpOptions);
  }
  
  getOneCommune(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(this.api + '/' + id, httpOptions);
  }
}
