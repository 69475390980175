import { ViewEncapsulation } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouteReuseStrategy } from '@angular/router';
import { merge } from 'rxjs';
import { app } from 'server';
import { CategorieService } from 'src/app/_services/categorie.service';
import { CommuneService } from 'src/app/_services/commune.service';
import { ProduitService } from 'src/app/_services/produit.service';
import { ResearchService } from 'src/app/_services/research.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  categories: any;
  communes: any;
  commune: any;
  domaine: any;
  searchForm: any;
  results: any;

  constructor(private route: ActivatedRoute, private router: Router, private researchService: ResearchService,private categorieService: CategorieService, private communeService: CommuneService, private formBuilder: FormBuilder, private produitService: ProduitService) {}

  get f() { return this.searchForm.controls; }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.commune = params["commune"];
      this.domaine = params["domaine"];
    });


    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false
    }

    this.categorieService.getDisplayedSearch().subscribe(data => {
      this.categories = data;
    });

    this.communeService.getAllCommunes().subscribe(data => {
      this.communes = data;
    });

    this.searchForm = this.formBuilder.group({
      domaine: new FormControl(''),
      commune: new FormControl(''),
      text: new FormControl(''),
    })

    if (this.commune || this.domaine) {
      this.searchForm.patchValue({
        'domaine': this.domaine,
        'commune': this.commune,
      })
    }
  }

  onSubmit(): void {
    //stockage en session de la recherche
    window.sessionStorage.setItem('domaine', this.searchForm.get("domaine").value);
    window.sessionStorage.setItem('commune', this.searchForm.get("commune").value);
    window.sessionStorage.setItem('text', this.searchForm.get("text").value);
    window.sessionStorage.setItem('page', '0');

    this.router.navigate(['/resultats']);
  }
}
