<main>
    <section class="section-musee-top">
      <img class="img-bandeau" src="assets/bandeau-haut-collections.png">
      <a href="#">
        <img class="logo" src="assets/logo.svg">
      </a>
      <div class="en-savoir-plus-container">
        <h2 id="collection-en-ligne">Collections en ligne</h2>
        <div>
          <a class="en-savoir-plus" target="_blank" href="https://www.museedecarnac.com/">MUSÉE DE PRÉHISTOIRE</a>
          <a class="en-savoir-plus" href="/en-savoir-plus">En savoir plus</a>
        </div>
      </div>
      <div class="search-bar-container">
        <app-search-bar class="app-serach-bar"></app-search-bar>
      </div>

      <div class="collection-container">
        <div class="text-container">
          <p class="text-collection">Bienvenue sur le portail des collections. Actuellement plus de 4000 plaques photographiques prises entre 1890 et 1940 sont à votre disposition. Découvrez-les grâce aux sélections ci-dessous ou en tapant des mots-clés dans le moteur de recherche.</p>
        </div>
        <div class="credit-container">
          <p class="text-credit">Crédit photo (hors plaques stéréoscopiques) : Cl. Z. Le Rouzic. Coll. Musée de Carnac</p>
          <p class="text-credit">Crédit photo (plaques stéréoscopiques) : Fonds Z. Le Rouzic. Coll. Musée de Carnac</p>
        </div>

        <app-ariane page="Accueil"></app-ariane>

        <div class="card-container">
          <div class="card" *ngFor="let cat of categories" [ngClass]="cat.nom">
            <div class="hover_color">
              <img class="img-card" src="/assets/{{cat.vignette}}">
              <div class="hover_color-inner">
                <div class="hover_title">
                  <div class="cross-top"></div>
                  <div class="cross-mid"></div>
                </div>
              </div>
              <a [routerLink]="['/collection', cat.slug]"></a>
            </div>
            <span class="card-bar"></span>
            <h2>{{ cat.nom }}</h2>
          </div>

        </div>
      </div>
    </section>
  </main>
