import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResearchService {

  private produits: any;

  constructor() { }

  setResults(data: any){
    this.produits = data;    
  }

  getResults(){
    let temp = this.produits;    
    this.clearData();
    return temp;
  }

  clearData(){
    this.produits = undefined;
  }
}
