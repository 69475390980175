<footer>
    <div class="footer-container">
      <p>Haut lieu du mégalithisme, Carnac expose dans son musée l’une des plus riches collections préhistoriques d’Europe : un voyage à travers 450 000 ans d’histoire humaine, du Paléolithique à l’époque gallo-romaine.</p>
      <a style="cursor: pointer; text-decoration: none;" href="https://www.museedecarnac.com/" target="_blank" class="uppercase">MUSÉE DE PRÉHISTOIRE</a>
      <div class="divider"></div>
      <p>Place Christian Bonnet
        <br/>02 97 52 22 04
        <br/><a class="link" href="mailto:contact@museedecarnac.fr">contact@museedecarnac.fr</a></p>
  
      <div class="link-footer">
        <a href="/mentions-legales">Mentions légales</a> | <a href="https://www.agence-11h10.fr/" target="_blank">Agence 11h10 Web & Com.</a>
      </div>
    </div>
  </footer>
  