import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appHoverColorInverse]'
})
export class HoverColorInverseDirective {

  constructor(private el: ElementRef) { }

  @Input() defaultColor = '';

  @Input() appHoverColorInverseBg = '';
  @Input() appHoverColorInverse = '';

  @HostListener('mouseenter') onMouseEnter() {
    this.hoverInverse(this.appHoverColorInverse, this.appHoverColorInverseBg);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hoverInverse('', '');
  }

  private hoverInverse(color: string, bg: string) {
    this.el.nativeElement.style.color = color;
    // Necessite l'attribut renseigné : [attr.data-bg]
    var background = this.el.nativeElement.getAttribute('data-bg');
    if (bg) {
      background = bg;
    }
    this.el.nativeElement.style.backgroundColor = background;
  }
}
