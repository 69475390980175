import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements OnInit {
@Input() images: string[] = [] ;
@Input('height') size?: number = 300;
@Input() chevron: boolean = true;
@Input() indicator: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
