import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/_services/auth.service';

import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const SIRET_API = 'https://entreprise.data.gouv.fr/api/sirene/v3/etablissements/';
import { User as UserModel } from 'src/app/_models/user';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  form: any = {
    email: null,
    nom: null,
    prenom: null,
    adresse: null,
    siret: null,
    tva: null,
    zipCode: null,
    country: null,
    locality: null,
    tel: null,
  };
  isSuccessful = false;
  isSignUpFailed = false;

  errorMessage = '';

  values = '';
  enseigne = '';
  dnone = 'd-none';
  dnone_validation = 'd-none';
  enseigne_1 = '';
  inputSiret = document.getElementById('societe');


  constructor(private authService: AuthService, private http: HttpClient) {
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const {email, nom, prenom, adresse, zipCode, country, locality} = this.form;
    this.authService.register(
      new UserModel(prenom, nom, email)).subscribe(
      data => {
        this.isSuccessful = true;
        this.isSignUpFailed = false;
      },
      err => {
        this.errorMessage = err.error.message;
        this.isSignUpFailed = true;
      }
    );
  }


  onKey(event: any) {
    this.values = event.target.value;

    this.http
      .get<any>(SIRET_API + this.values)
      .subscribe(
        data => {
          if (data.etablissement.enseigne_1 !== undefined) {
            this.enseigne = data.etablissement.unite_legale.denomination;
            this.dnone = 'd-none';
            this.dnone_validation = 'd-block';
            this.inputSiret = data.etablissement.unite_legale.denomination;
          }
        },
        error => {
          this.dnone = 'd-block';
          this.dnone_validation = 'd-none';
        }
      );

    this.dnone = 'd-none';
  }
}
