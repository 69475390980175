import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MetadataService } from 'src/app/_services/metadata.service';
import { ProduitService } from 'src/app/_services/produit.service';


@Component({
  selector: 'app-personnage-portrait-detail',
  templateUrl: './personnage-portrait-detail.component.html',
  styleUrls: ['./personnage-portrait-detail.component.scss']
})
export class PersonnagePortraitDetailComponent implements OnInit {

  slug: any;
  idCat: any;
  collectionName: any;
  collection: any;
  errorMessage: any;
  product: any = [];
  categorie: any = [];

  constructor(private productService: ProduitService, private route: ActivatedRoute, private metadataService: MetadataService) {}

  ngOnInit() {
    this.collectionName = this.route.snapshot.params['collectionName'];
    this.slug = this.route.snapshot.params['slug'];

    this.productService.getOneProducts(this.slug).subscribe(
      data => {
        this.product = data;
        if (this.collectionName == 'resultats') {
          this.collectionName = this.product.categories[1].nom;
          this.collectionName = this.rename(this.collectionName)
        }
        if (this.metadataService) {
          this.metadataService.updateMetadata({
            title: this.product.titre,
            description: 'Photographie ' + this.product.titre
          });
        }
      },
      err => {
        this.errorMessage = err.error.detail;
        console.warn("err: " , this.errorMessage );
      }
    )
  }

  rename(title: string){
    if(title.includes('-')){
      title = title.replace(/-/g, " ");
    }
    title = title.toUpperCase();

    return title
  }
}
