<main>
    <section class="section-musee-top">
      <img class="img-bandeau" src="assets/bandeau-haut-collections.png">
      <a href="/">
        <img class="logo" src="assets/logo.svg">
      </a>

      <div class="en-savoir-plus-container">
        <h2>Collection en ligne</h2>
        <div>
          <a class="en-savoir-plus" target="_blank" href="https://www.museedecarnac.com/">MUSÉE DE PRÉHISTOIRE</a>
          <a class="en-savoir-plus" href="/en-savoir-plus">En savoir plus</a>
        </div>
      </div>
      <div class="search-bar-container">
        <app-search-bar></app-search-bar>
      </div>

      <div class="collection-container">
        <div class="text-container">
          <p class="text-collection">Bienvenue sur le portail des collections. Actuellement plus de 4000 plaques photographiques prises entre 1890 et 1940 sont à votre disposition. Découvrez-les grâce aux sélections ci-dessous ou en tapant des mots-clés dans le moteur de recherche.</p>
        </div>
        <div class="credit-container">
          <p class="text-credit">Crédit photo (hors plaques stéréoscopiques) : Cl. Z. Le Rouzic. Coll. Musée de Carnac</p>
          <p class="text-credit">Crédit photo (plaques stéréoscopiques) : Fonds Z. Le Rouzic. Coll. Musée de Carnac</p>
        </div>
        <div class="recherche-container">
          <div>
            recherche :
          </div>
          <div class="recherche-termes">
            <p *ngIf="domaine !== null">{{unslugify(domaine) | uppercase}} 	&nbsp;</p>
            <p *ngIf="commune !== null">{{unslugify(commune) | uppercase}} 	&nbsp;</p>
            <p *ngIf="text !== null">{{unslugify(text) | uppercase}} 	&nbsp;</p>
          </div>

        </div>
        <div *ngIf="products?.length > 0" class="text-container">
          {{products?.length}} documents trouvés
        </div>
        <div *ngIf="products?.length == 0" class="text-container">
          Aucune réponse ne correspond à votre recherche
        </div>
        <div class="card-container">

          <div class="card card-small" *ngFor="let item of products | paginate: { itemsPerPage: 12, currentPage: p }">
            <div class="hover_color">
              <img class="img-card" src="{{item.vignette}}">
              <div class="hover_color-inner">
                <div class="hover_title">
                  <div class="cross-top"></div>
                  <div class="cross-mid"></div>
                </div>
              </div>
              <a [routerLink]="[item.slug]" [queryParams]="{domaine: domaine, text: text, commune: commune}"></a>
            </div>
            <div class="card-bar-small"></div>
            <h2>{{ item.titre }}</h2>
          </div>

        </div>
        <div class="pagination" *ngIf="products?.length > 12">
          <pagination-controls nextLabel="Suivant" previousLabel="Précédent" (pageChange)="handlePageChange($event)" class="float-right"></pagination-controls>
        </div>

      </div>
    </section>
  </main>
