<div class="search-container">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">

    <mat-form-field appearance="fill">
      <mat-label>Domaines</mat-label>
      <mat-select formControlName="domaine" >
        <mat-option class="domaines" [value]="''">Tous les domaines</mat-option>
        <mat-option class="domaines" *ngFor="let cat of categories" [value]="cat.slug">{{ cat.nom }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Communes</mat-label>
      <mat-select formControlName="commune" >
        <mat-option [value]="''">Toutes les communes</mat-option>
        <mat-option *ngFor="let commune of communes" [value]="commune.slug">{{ commune.nom }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="search-input" appearance="fill">
      <mat-label>Rechercher par mot clé</mat-label>
      <input formControlName="text" class="input" matInput placeholder="Votre Recherche">
    </mat-form-field>

    <button type="submit" class="searchIcon">
      Lancer la recherche
    </button>
  </form>
</div>
