import { Component, OnInit } from '@angular/core';
import { MetadataService } from 'src/app/_services/metadata.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
    if (this.metadataService) {
      this.metadataService.updateMetadata({
        title: 'Page non trouvée',
        description: '404 not found'
      });
    }
  }

}
