export class User {
  id!: number;
  gender!: string;
  lastname: string;
  firstname: string;
  email: string;
  adresse!: string;
  zipCode!: string;
  country!: string;
  locality!: string;
  fixNumber!: string;
  phoneNumber!: string;
  fax!: string;

  constructor(lastname: string, firstname: string, email: string) {
    this.lastname = lastname;
    this.firstname = firstname;
    this.email = email;
  }

  setId(id: number) {
    this.id = id;
  }
}
