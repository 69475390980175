import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { JsonLDMetadataService } from './_services/json-ldmetadata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'front';

  constructor(private jsonLDService: JsonLDMetadataService, private router: Router) { }

  ngOnInit(): void {
    this.jsonLDService.insertSchema(JsonLDMetadataService.websiteSchema(window.location.href, 'Musée de Carnac'), 'retest');
  }

}
