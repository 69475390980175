<div class="card col-8 mx-auto">
    <form #contactForm="ngForm" (ngSubmit)="onFormSubmit(contactForm)">
        <div class="form-group">
            <label>Nom et Prénom</label>
            <input type="text" ngModel name="nom_prenom" id="nom_prenom" class="form-control">
            <label>Email</label>
            <input type="text" ngModel name="email" id="email" class="form-control">
            <label>Téléphone</label>
            <input type="text" ngModel name="tel" id="tel" class="form-control">
            <label>Message</label>
            <textarea type="text" ngModel name="message" id="message" class="form-control"></textarea>
            <button class="btn btn-primary" type="submit">Envoyer</button>
        </div>
    </form>
</div>