import { Component, OnInit } from '@angular/core';
import { MetadataService } from 'src/app/_services/metadata.service';
@Component({
  selector: 'app-en-savoir-plus',
  templateUrl: './en-savoir-plus.component.html',
  styleUrls: ['./en-savoir-plus.component.scss']
})
export class EnSavoirPlusComponent implements OnInit {

  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
    if (this.metadataService) {
      this.metadataService.updateMetadata({
        title: 'En savoir plus',
        description: 'Description et histoire du musée de Carnac'
      });
    }
  }

}
