import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProduitService {
  api = `${environment.api}/api/`;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  getAllProducts(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(this.api + 'produits', httpOptions);
  }

  getProductsByCategory(id: any): Observable<any> {  
    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(this.api + 'categories/' + id, httpOptions);
  }
  
  getOneProducts(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(this.api + 'produits/' + id, httpOptions);
  }

  UpdateProduct(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    var tabData = {

    };

    return this.http.put(this.api + '/' + id, tabData, httpOptions);
  }

  searchProduct(domaine: string, commune: string, text: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    
    var tabData = {
      'categorie': domaine,
      'commune': commune,
      'text': text
    };

    return this.http.post(this.api + 'produits/search', tabData, httpOptions);
  }
}
