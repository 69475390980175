import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CategorieService {
  api = `${environment.api}/api/`;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  getDisplayedCategories(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(this.api + 'getDisplayedSite', httpOptions);
  }
  getOneCategories(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(this.api + '/' + id, httpOptions);
  }
  getAllCategories(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(this.api + 'categories', httpOptions);
  }
  getDisplayedSearch(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(this.api + 'getDisplayedSearch', httpOptions);
  }

}
