import { Component, OnInit } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MetadataService } from 'src/app/_services/metadata.service';
import { ProduitService } from 'src/app/_services/produit.service';

@Component({
  selector: 'app-personnage-portrait',
  templateUrl: './personnage-portrait.component.html',
  styleUrls: ['./personnage-portrait.component.scss']
})
export class PersonnagePortraitComponent implements OnInit {
  collection = [];
  collectionName: string = "";
  idCategory: any;
  errorMessage: any;
  products: any = [];
  p: number = 1;



  constructor(private productService: ProduitService, private route: ActivatedRoute, private router: Router, private metadataService: MetadataService) {}

  ngOnInit(): void {

    this.collectionName = this.route.snapshot.params['collectionName'];
    this.getProductsByCategories();
    this.route.queryParams.subscribe(params => {
      this.p = params["page"];
    });
  }
  getProductsByCategories() {
    this.productService.getProductsByCategory(this.collectionName).subscribe(
      data => {
        this.products = data.produits;
        console.log(this.products);

        if (this.metadataService) {
          this.metadataService.updateMetadata({
            title: this.collectionName,
            description: 'liste des articles présents dans la collection ' + this.collectionName
          });
        }
      },
      err => {
        this.errorMessage = err.error.detail;
        console.warn("err: " , this.errorMessage );
      }
    )
  }

  handlePageChange(event: any) {
    this.p = event;
    let queryParams = {page: this.p}
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  rename(title: string){
    if(title.includes('-')){
      title = title.replace(/-/g, " ");
      title = title[0].toUpperCase() + title.slice(1);;
    }
    return title
  }
}
