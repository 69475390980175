<app-bandeau-image-titre
  [titre]="'CONNEXION'"
  [image]="'bandeau-productions.jpg'"
  [afficherBtnCatalogue]='true'>
</app-bandeau-image-titre>
<div class="navigation-page">
  <div class="ariane">
    <span>Synfolia
      <span class="pages">| Connexion</span>
    </span>
  </div>
</div>

<section class="login-card-section">
  <form class="login-card" *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm"
        novalidate>
    <input type="text" class="login-identifiant" name="username" [(ngModel)]="form.username" required
           #username="ngModel" placeholder="Identifiant">
    <div class="alert alert-danger" role="alert" *ngIf="username.errors && f.submitted">
      Username is required!
    </div>
    <input type="password" class="login-password" name="password" id="password" [(ngModel)]="form.password" required
           minlength="4" #password="ngModel" placeholder="Mot de passe"/>
    <i class="fas fa-eye-slash toggle-password" id="togglePassword"></i>
    <div class="alert alert-danger" role="alert" *ngIf="password.errors && f.submitted">
      <div *ngIf="password.errors.required">Password is required</div>
      <div *ngIf="password.errors.minlength">
        Password must be at least 4 characters
      </div>
    </div>

    <a href="#" class="login-pass-forget">Mot de passe oublié ?</a>
    <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isLoginFailed">
      Login failed: {{ errorMessage }}
    </div>
    <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-pulse" [fullScreen]="false">
      <p style="color: white"> Chargement... </p>
    </ngx-spinner> -->

    <div class="login-button-div">
      <button class="btn login-button" type="submit">Connexion</button>
      <a routerLink="/register" class="btn register-button">Créer mon compte</a>
    </div>

    <div class="alert alert-success" *ngIf="isLoggedIn">
      Logged in as {{ roles }}.
    </div>
  </form>
</section>
<app-bandeau-pepinieres id="app-bandeau-pepinieres"></app-bandeau-pepinieres>
