// APP
import { AppRoutingModule } from './app-routing.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
// import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

// _DIRECTIVES
import {HoverColorInverseDirective} from './_directives/hover-color-inverse.directive';

// _HELPERS
// import { authInterceptorProviders } from './_helpers/auth.interceptor';

// _PIPES
import {SlugifyPipe} from "./_pipes/slugify";

// STRUCTURE
import { FooterComponent } from './structure/footer/footer.component';
import { MenuComponent } from './structure/menu/menu.component';
import { ContactComponent } from './structure/contact/contact.component';

// COMPONENTS
import { CarouselComponent } from './components/carousel/carousel.component';
import { BuilderPageComponent } from './components/builder-page/builder-page.component';

// PAGES
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { PersonnagePortraitComponent } from './pages/personnage-portrait/personnage-portrait.component';
import { PersonnagePortraitDetailComponent } from './pages/personnage-portrait-detail/personnage-portrait-detail.component';
import { SearchBarComponent } from './pages/search-bar/search-bar.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material/material.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ResultatsRechercheComponent } from './pages/resultats-recherche/resultats-recherche.component';
import { ArianeComponent } from './components/ariane/ariane.component';
import { EnSavoirPlusComponent } from './pages/en-savoir-plus/en-savoir-plus.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import { MetadataService } from './_services/metadata.service';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    FooterComponent,
    MenuComponent,
    HomeComponent,
    CarouselComponent,
    BuilderPageComponent,
    RegisterComponent,
    ContactComponent,
    HoverColorInverseDirective,
    SlugifyPipe,
    PersonnagePortraitComponent,
    PersonnagePortraitDetailComponent,
    SearchBarComponent,
    ResultatsRechercheComponent,
    ArianeComponent,
    EnSavoirPlusComponent,
    MentionsLegalesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    CommonModule,
    NgxPaginationModule,
    BrowserModule,
    RouterModule,
    MaterialModule,
    SlickCarouselModule,
    ReactiveFormsModule,
  ],
  providers: [
    // authInterceptorProviders
    MetadataService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
