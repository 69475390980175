import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  onFormSubmit(contactForm:NgForm){
    const body = {
      "nom_prenom": contactForm.form.value['nom_prenom'],
      "email": contactForm.form.value['email'],
      "tel": contactForm.form.value['tel'],
      "message": contactForm.form.value['message']
    }
    this.http.post<any>('http://localhost:10010/api/contact', body).subscribe(response => {
    });

  }

  resetForm(contactForm:NgForm){
    contactForm.reset();
  }

}
