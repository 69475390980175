<main>
  <section class="section-musee-top">
    <img class="img-bandeau" src="assets/bandeau-haut-collections.png">
    <a href="#">
      <img class="logo" src="assets/logo.svg">
    </a>
    <div class="en-savoir-plus-container">
      <h2>Collection en ligne</h2>
      <div>
        <a class="en-savoir-plus" target="_blank" href="https://www.museedecarnac.com/">MUSÉE DE PRÉHISTOIRE</a>
        <a class="en-savoir-plus" href="/en-savoir-plus">En savoir plus</a>
      </div>
    </div>
    <div class="search-bar-container">
      <app-search-bar></app-search-bar>
    </div>

    <div class="collection-container">
      <div class="ariane">
        <app-ariane [page]="product.titre" [collection]="collectionName"></app-ariane>
      </div>
      <div class="collection-container-detail">
        <div class="slider-container">
          <img [src]="product.vignette">
        </div>
        <div class="text-detail-container">

          <h2 class="span-titre"> {{ product.titre }} | {{product.commune.nom}}</h2>
          <br/>
          <span class="span-numeroinv">N° d'inventaire : {{ product.inv_saisie }}</span>
          <p class="desc-1"> {{ product.denomination }}</p>
          <p class="dimensions"> {{ product.dimensions }}</p>
          <p class="description"> {{ product.description }}</p>
        </div>
      </div>
    </div>
  </section>
</main>
