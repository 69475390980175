import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import slugify from "slugify";

@Pipe({ name: 'slugify' })
export class SlugifyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(text: string) {
    return slugify(text, {
      replacement: '-',  // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: true,      // convert to lower case, defaults to `false`
      strict: true,     // strip special characters except replacement, defaults to `false`
      locale: 'vi',       // language code of the locale to use
      trim: true         // trim leading and trailing replacement chars, defaults to `true`
    });
  }
}
