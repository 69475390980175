import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonLDMetadataService {
	static scriptType = 'application/json+ld';
  static websiteSchema = (url?: string, name?: string) => {
    return {
      '@context': "https://schema.org",
      "@graph":
        [
          {
            "@type":"Organization",
            "@id":"https://www.museedecarnac.com/#organization",
            "name":"Mus\u00e9e de Carnac - Collection en ligne",
            "url": url,
            "sameAs":[
              "https://www.facebook.com/pages/Mus\u00e9e-de-pr\u00e9histoire-de-Carnac/1378579125719965"
            ],
            "logo":{
              "@type":"ImageObject","@id":"https://www.museedecarnac.com/#logo",
              "inLanguage":"fr-FR","url":url+"/assets/logo.svg",
              "width":285.619,
              "height":173.456,
              "caption":"Mus\u00e9e de Carnac - Collection en ligne"
            },
            "image":{
              "@id": url+"/#logo"
            }
          },
          {
            "@type":"WebSite",
            "@id": url+"/#website",
            "url":url,
            "name":"Mus\u00e9e de Carnac - Collection en ligne",
            "description":"Mus\u00e9e de Carnac - Collection en ligne",
            "publisher":{
              "@id":"https://www.museedecarnac.com/#organization"
            },
            "potentialAction":[
              {
                "@type":"SearchAction",
                "target":url+"resultats",
                "query-input":[
                  "domaine=search_term_string",
                  "commune=search_term_string",
                  "text=search_term_string",
                ]
              }
            ],
            "inLanguage":"fr-FR"
          },
          {
            "@type":"WebPage",
            "@id":url+"/#webpage",
            "url":url,
            "name":"Mus\u00e9e de Carnac - Collection en ligne",
            "isPartOf":{"@id":"https://www.museedecarnac.com/#website"},
            "about":{
              "@id":"https://www.museedecarnac.com/#organization"
            },
            "datePublished":"2016-12-03T12:52:36+00:00",
            "dateModified":"2021-06-04T13:21:31+00:00",
            "inLanguage":"fr-FR",
            "potentialAction":[
              {
                "@type":"ReadAction",
                "target":[
                  "https://www.museedecarnac.com/"
                ]
              }
            ]
          }
        ]
    }
  }

constructor(@Inject(DOCUMENT) private _document: Document) {}

	removeStructuredData(): void {
		const els: Element[] = [];
		[ 'structured-data', 'structured-data-org' ].forEach(c => {
			els.push(...Array.from(this._document.head.getElementsByClassName(c)));
		});
		els.forEach(el => this._document.head.removeChild(el));
	}

	insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
		let script;
		let shouldAppend = false;
		if (this._document.head.getElementsByClassName(className).length) {
			script = this._document.head.getElementsByClassName(className)[0];
		} else {
			script = this._document.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.type = JsonLDMetadataService.scriptType;
		script.text = JSON.stringify(schema);
		if (shouldAppend) {
			this._document.head.appendChild(script);
		}
	}
}
