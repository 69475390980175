<app-bandeau-image-titre
  [titre]="'INSCRIPTION'"
  [image]="'bandeau-productions.jpg'"
  [afficherBtnCatalogue]='true'>
</app-bandeau-image-titre>
<div class="navigation-page">
  <div class="ariane">
    <span>Synfolia
      <span class="pages">| Inscription</span>
    </span>
  </div>
</div>
<section class="login-card-section">
  <form class="login-card" *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm"
        novalidate>

    <div class="error-div">
      <input type="text" class="login-identifiant" placeholder="Nom"
             name="nom" [(ngModel)]="form.nom" required minlength="3"
             maxlength="20" #nom="ngModel">
      <div class="error" *ngIf="nom.errors && f.submitted">
        <span *ngIf="nom.errors.required">Veuillez saisir un nom</span>
        <span *ngIf="nom.errors.minlength">Le nom doit faire 3 caractères minimum</span>
        <span *ngIf="nom.errors.maxlength">Le nom ne peut faire que 20 caractères maximum</span>
      </div>
    </div>


    <div class="error-div">
      <input type="text" class="login-identifiant" placeholder="Prénom"
             name="prenom" [(ngModel)]="form.prenom" required minlength="3"
             maxlength="20" #prenom="ngModel">
      <div class="error" *ngIf="prenom.errors && f.submitted">
        <span *ngIf="prenom.errors.required">Veuillez saisir un prénom</span>
        <span *ngIf="prenom.errors.minlength">Le prénom doit faire 3 caractères minimum</span>
        <span *ngIf="prenom.errors.maxlength">Le prénom ne peut faire que 20 caractères maximum</span>
      </div>
    </div>

    <div class="error-div">
      <input type="text" class="login-identifiant" placeholder="Mail"
             name="email" [(ngModel)]="form.email" required minlength="10"
             maxlength="150" #email="ngModel">
      <div class="error" *ngIf="email.errors && f.submitted">
        <span *ngIf="email.errors.required">Veuillez saisir un e-mail</span>
        <span *ngIf="email.errors.minlength">L'e-mail doit faire 10 caractères minimum</span>
        <span *ngIf="email.errors.maxlength">Votre e-mail ne peut faire que 150 caractères maximum</span>
      </div>
    </div>

    <div class="error-div">
      <input type="text" class="login-identifiant" placeholder="Siret"
             name="siret" [(ngModel)]="form.siret" required minlength="10"
             maxlength="150" #siret="ngModel"
             (keyup)="onKey($event)"
             (keydown.backspace)="onKey($event)"
             (keydown.ctrl.v)="onKey($event)"
             (keydown.ctrl.a)="onKey($event)"
             (change)="onKey($event)">
      <div class="error" *ngIf="siret.errors && f.submitted">
        <span *ngIf="siret.errors.required">Veuillez saisir un siret</span>
        <span *ngIf="siret.errors.minlength">Le siret doit faire 10 caractères minimum</span>
        <span *ngIf="siret.errors.maxlength">Le siret ne peut faire que 150 caractères maximum</span>
      </div>
    </div>

    <div class="spinner-border spinner-border-sm text-dark spinner-siret {{dnone}}" id="spinner" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>

    <input type="text" class="login-identifiant" name="societe" value="{{enseigne}}" placeholder="Nom de la société">

    <div class="error-div">
      <input type="text" class="login-identifiant" placeholder="Numéro de TVA"
             name="tva" [(ngModel)]="form.tva" required minlength="5"
             maxlength="30" #tva="ngModel">
      <div class="error" *ngIf="tva.errors && f.submitted">
        <span *ngIf="tva.errors.required">Veuillez saisir un numéro de TVA</span>
        <span *ngIf="tva.errors.minlength">La TVA doit faire 5 caractères minimum</span>
        <span *ngIf="tva.errors.maxlength">La TVA ne peut faire que 30 caractères maximum</span>
      </div>
    </div>

    <div class="error-div">
      <input type="text" class="login-identifiant" placeholder="Téléphone"
             name="tel" [(ngModel)]="form.tel" required minlength="9"
             maxlength="11" #tel="ngModel">
      <div class="error" *ngIf="tel.errors && f.submitted">
        <span *ngIf="tel.errors.required">Veuillez saisir un numéro de Téléphone</span>
        <span *ngIf="tel.errors.minlength">Le numéro de téléphone doit faire 10 caractères minimum</span>
        <span *ngIf="tel.errors.maxlength">Le numéro de téléphone ne peut faire que 10 caractères maximum</span>
      </div>
    </div>

    <div class="error-div">
      <input type="text" class="login-identifiant" placeholder="Fax"
             name="fax" [(ngModel)]="form.fax" required minlength="5"
             maxlength="20" #fax="ngModel">
      <div class="error" *ngIf="fax.errors && f.submitted">
        <span *ngIf="fax.errors.required">Veuillez saisir un numéro de Fax</span>
        <span *ngIf="fax.errors.minlength">Le numéro de fax doit faire 5 caractères minimum</span>
        <span *ngIf="fax.errors.maxlength">Le numéro de fax ne peut faire que 20 caractères maximum</span>
      </div>
    </div>

    <div class="d-flex">
      <input type="checkbox" required="required">
      <label class="control-label label-rgpd">En soumettant ce formulaire, j’accepte que
        les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me
        recontacter, pour m’envoyer des informations, dans le cadre de la relation commerciale qui découle de cette
        demande. <a href="">En savoir plus</a>
      </label>
    </div>

    <div class="login-button-div">
      <button class="login-button" type="submit">Demande d'inscription</button>
      <a href="/login" class="register-button">Connexion</a>
    </div>
  </form>
</section>
<app-bandeau-pepinieres id="app-bandeau-pepinieres"></app-bandeau-pepinieres>

<!--
<div class="card card-container">
  <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="form-group">
      <label for="username">Username</label>
      <input type="text" class="form-control" name="username" [(ngModel)]="form.username" required minlength="3"
             maxlength="20" #username="ngModel"/>
      <div class="alert-danger" *ngIf="username.errors && f.submitted">
        <div *ngIf="username.errors.required">Username is required</div>
        <div *ngIf="username.errors.minlength">Username must be at least 3 characters</div>
        <div *ngIf="username.errors.maxlength">Username must be at most 20 characters</div>
      </div>
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input type="email" class="form-control" name="email" [(ngModel)]="form.email" required email #email="ngModel"/>
      <div class="alert-danger" *ngIf="email.errors && f.submitted">
        <div *ngIf="email.errors.required">Email is required</div>
        <div *ngIf="email.errors.email">
          Email must be a valid email address
        </div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary btn-block">Sign Up</button>
    </div>

    <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
      Signup failed!<br/>{{ errorMessage }}
    </div>
  </form>

  <div class="alert alert-success" *ngIf="isSuccessful">
    Your registration is successful!
  </div>
</div>-->
