<div id="carouselIndicators" class="carousel slide" data-bs-ride="carousel">
    <div 
        class="carousel-indicators"
        *ngIf="indicator"
    >
        <button 
            type="button"
            data-bs-target="#carouselIndicators"
            aria-current="true"
            *ngFor="let image of images; let i = index; let first = first"
            [attr.aria-label]="'Slide ' + (i+1)"
            [attr.data-bs-slide-to]="i"
            [ngClass]="{ active: first }"
        ></button>
    </div>
    <div 
        class="carousel-inner"
        [style.max-height]="size+'px'"
    >
        <div 
            class="carousel-item"
            *ngFor="let image of images; let i = index; let first = first"
            [ngClass]="{ active: first }"
        >
            <img src="../../../assets/{{image}}" class="d-block w-100" alt="...">
        </div>
    </div>
    <button *ngIf="chevron" class="carousel-control-prev" type="button" data-bs-target="#carouselIndicators" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button *ngIf="chevron" class="carousel-control-next" type="button" data-bs-target="#carouselIndicators" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>