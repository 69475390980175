import { Component, OnInit } from '@angular/core';
import { MetadataService } from 'src/app/_services/metadata.service';

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.scss']
})
export class MentionsLegalesComponent implements OnInit {

  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
    if (this.metadataService) {
      this.metadataService.updateMetadata({
        title: 'Mentions légales',
        description: 'mentions légales du site'
      });
    }
  }

}
