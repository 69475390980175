import { Component, Input, OnInit } from '@angular/core';


interface Collection {
  nom: string;
  id: string;
}
@Component({
  selector: 'app-ariane',
  templateUrl: './ariane.component.html',
  styleUrls: ['./ariane.component.scss']
})
export class ArianeComponent implements OnInit {

  @Input() page: string | undefined;
  @Input() collection: any;

  idCat: any;
  constructor() {
  }

  ngOnInit(): void {         
  }

  slugify(title: string){
    if(title.includes(' ')){
      title = title.replace(/ /g, "-").toLowerCase();      
    }
    return title
  }

  rename(title: string){
    if(title.includes('-')){
      title = title.replace(/-/g, " ");
      title = title[0].toUpperCase() + title.slice(1);;  
    }
    return title
  }

}
