import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuilderPageComponent } from './components/builder-page/builder-page.component';
import { ContactComponent } from './structure/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

import { MapSiteComponent } from './structure/map-site/map-site.component';
import { PersonnagePortraitComponent } from './pages/personnage-portrait/personnage-portrait.component';
import { PersonnagePortraitDetailComponent } from './pages/personnage-portrait-detail/personnage-portrait-detail.component';
import { EnSavoirPlusComponent } from './pages/en-savoir-plus/en-savoir-plus.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import { ResultatsRechercheComponent } from './pages/resultats-recherche/resultats-recherche.component';
const routes: Routes = [
  {path: '', component: HomeComponent},
  {
    path: 'sitemap-1-google.xml',
    component: MapSiteComponent
  },
  {path: '', component: HomeComponent},
  {path: 'collection/:collectionName', component: PersonnagePortraitComponent},
  {path: 'collection/:collectionName/:slug', component: PersonnagePortraitDetailComponent},
  {path: ':collectionName/:slug', component: PersonnagePortraitDetailComponent},
  {path: 'en-savoir-plus', component: EnSavoirPlusComponent},
  {path: 'mentions-legales', component: MentionsLegalesComponent},
  {path: 'resultats', component: ResultatsRechercheComponent },
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
