import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetadataService } from 'src/app/_services/metadata.service';
import { ProduitService } from 'src/app/_services/produit.service';

@Component({
  selector: 'app-resultats-recherche',
  templateUrl: './resultats-recherche.component.html',
  styleUrls: ['./resultats-recherche.component.scss']
})
export class ResultatsRechercheComponent implements OnInit {
  p: number = 0;
  products: any;
  domaine: any;
  commune: any;
  text: any;

  orderby: string = '';
  constructor(private produitService: ProduitService, private router: Router, private route: ActivatedRoute, private metadataService: MetadataService) {

  }

  ngOnInit(): void {
    this.domaine = window.sessionStorage.getItem('domaine');
    this.commune = window.sessionStorage.getItem('commune');
    this.text = window.sessionStorage.getItem('text');
    this.p = Number(window.sessionStorage.getItem('page'));
    this.produitService.searchProduct(this.domaine, this.commune, this.text).subscribe(data => {
      this.products = data;
      if (this.metadataService) {
        this.metadataService.updateMetadata({
          title: 'Résultats de la recherche sur ' + this.domaine + ' ' + this.commune + ' ' + this.text,
          description: 'liste des résultats de la recherche'
        });
      }
    })
  }

  handlePageChange(event: any) {
    this.p = event;
    window.sessionStorage.setItem('page', (this.p).toString());
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  unslugify(string: string) {
    string = string.replace(/-/g, ' ');
    return string;
  }
}
