<main>
  <section class="section-musee-top">
    <img class="img-bandeau" src="assets/bandeau-haut-collections.png">
    <a href="/">
      <img class="logo" src="assets/logo.svg">
    </a>

    <div class="en-savoir-plus-container">
      <h2>Mentions légales</h2>
      <a class="en-savoir-plus" href="/">Accueil</a>
    </div>
  </section>

  <div class="ariane">
    <app-ariane page="mentions légales"></app-ariane>
  </div>

  <div class="mentions-legales-container">
    <p class="text-mentions-legales">
      <span class="center">CONDITIONS GENERALES D’UTILISATION ET MENTIONS LEGALES</span>

      <br/><br/><span>Article 1 – OBJET</span>

      <br/><br/>Les présentes conditions générales d'utilisation ont pour objet l'encadrement des modalités de mise à disposition des services du site www.collections.museedecarnac.com et leur utilisation par l'Utilisateur.

      <br/><br/>Les conditions générales doivent être acceptées par tout Utilisateur souhaitant accéder au site. Elles constituent le contrat entre le site et l'Utilisateur. L'accès au site par l'Utilisateur signifie son acceptation des présentes conditions générales d'utilisation.

      <br/><br/><span>Article 2 – MENTIONS LEGALES</span>

      <br/><br/><span>2.1 Propriété du site</span>

      <br/><br/>La Ville de Carnac, dont le siège social est situé Place Christian-Bonnet – 56340 Carnac – SIRET : 215 600 347 00060 - APE : 9103Z, est propriétaire du site www.collections.museedecarnac.com.

      <br/><br/><span>2.2 Direction de la publication</span>

      <br/><br/>Directeur de la publication : le Maire de Carnac.

      <br/><br/>Responsable éditorial : le directeur du Musée de Préhistoire.

      <br/><br/>Contributeurs : l’équipe du Musée de Préhistoire.

      <br/><br/><span>2.3 Hébergeur</span>

      <br/><br/>Société Agence 11H10, 11 rue du Clos Courtel, 35700 RENNES, au capital de 3 000 € RCS RENNES SIRET N°84204545200013

      <br/><br/><span>ARTICLE 3 – PRESENTATION DU SITE</span>

      <br/><br/>Le site permet à l'Utilisateur un accès gratuit à la base documentaire du Musée de Préhistoire de Carnac, comprenant :

      <br/><br/>•	Notices des œuvres
      <br/>•	Visuels des œuvres
      <br/>•	Parcours thématiques

      <br/><br/><span>ARTICLE 4 – CONDITIONS D'UTILISATION DES CONTENUS</span>

      <br/><br/>L'ensemble des données qui compose le site collections.museedecarnac.com est la propriété de la Ville de Carnac. À ce titre, le site est protégé par la législation française, communautaire et internationale sur le droit de la propriété intellectuelle et le droit à l'image.

      <br/><br/><span>4.1 Conditions d’utilisation des images numériques des items</span>

      <br/><br/>Les images numériques présentes sur le portail, compte tenu du statut des œuvres représentées et des droits de l'auteur de l'image numérique, sont soit inscrites sous la marque domaine public, soit placées sous licence Creative Commons, soit demeurent "Tous droits réservés" à leurs auteurs ou ayants droit des auteurs. L’Utilisateur sera autorisé ou non à faire usage des images selon les différents cas :

      <br/><br/>Les photographies d’œuvres en deux dimensions appartenant au domaine public (exemple : plaques photographiques datant de 1890-1930) sont publiées sous la Marque du domaine public. L’Utilisateur peut copier, modifier et distribuer l’image, même à des fins commerciales, sans avoir besoin d’une autorisation, sous réserve de mentionner le nom de l'auteur de l'œuvre.

      <br/><br/>Les photographies d’inventaire d’objets archéologiques en trois dimensions, appartenant au domaine public, seront publiées en CC0 (tous droits cédés, sauf le droit moral d’auteur pour le photographe). L’Utilisateur peut copier, modifier et distribuer l’image, même à des fins commerciales, sans avoir besoin d’une autorisation, sous réserve de mentionner le nom de l'auteur de l'œuvre et de l’auteur de la photographie.

      <br/><br/>Les photographies montrant des compositions ou des mises en scène d’objets archéologiques en trois dimensions (acte de création), appartenant au domaine public, seront publiées en CC BY SA (citation obligatoire de l’auteur, utilisation permise uniquement dans les mêmes conditions, utilisation commerciale autorisée). Le titulaire des droits autorise la libre exploitation de l'image, le partage, la copie, la modification, y compris à des fins commerciales, à condition de préciser si des modifications ont été apportées, de partager l'image modifiée dans les mêmes conditions c'est-à-dire sous la même licence ou licence équivalente et de mentionner le nom de l'auteur (des auteurs) de l'œuvre.

      <br/><br/>Les photographies d’œuvres protégées par le droit d’auteur, notamment concernant le fonds iconographique, seront publiées, selon le choix des titulaires des droits, sous une licence Creative Commons ou sous la mention de réserve « Tous droits réservés ». Toute exploitation des images d'items concernées est interdite, en dehors des exceptions au droit d'auteur définies par l'article L122-5 du Code de la propriété intellectuelle. Toute reproduction, représentation ou diffusion, par quelque moyen que ce soit, d'une œuvre de l'esprit en violation des droits de l'auteur, tels qu'ils sont définis et réglementés par la loi, est susceptible d'être sanctionnée au titre du délit de contrefaçon défini par l'article L335-3 du Code de la propriété intellectuelle.

      <br/><br/><span>4.2 Crédits à indiquer</span>

      <br/><br/>Les crédits à indiquer lors de l'exploitation d'une photographie en ligne, dans les conditions prévues sur le site, sont les suivantes :

      <br/><br/>Pour les œuvres placées sous la marque "Domaine Public" : indication du nom de l'auteur de l'œuvre représentée sur la photographie et de la mention "Collections Musée de Préhistoire de Carnac".

      <br/><br/>Pour les œuvres placées sous licence Creative Commons (licences CC0 et CC BY SA) : indication du nom de l'auteur de l'œuvre représentée sur la photographie – indication du nom du photographe ayant effectué la photographie et indication de la mention "Collections Musée de Préhistoire de Carnac".

      <br/><br/><span>4.3 Citation de la source</span>

      <br/><br/>Conformément à la réglementation, aux licences précitées et aux bonnes pratiques scientifiques, tout utilisateur des documents, images et textes du site doit en indiquer la source « Collections Musée de Préhistoire de Carnac » et fournir le lien www.collections.museedecarnac.com.

      <br/><br/>Le Musée de Carnac propose aux internautes de lui signaler pour information toute réutilisation de ses fichiers.

      <br/><br/>Les données de ce site constituent un entrepôt OAI-PMH. Elles sont exposées au format Dublin Core simple, format de description garantissant l’interopérabilité entre les systèmes. Les métadonnées des notices sont mises à disposition et peuvent être moissonnées et exposées librement à l’aide du protocole OAI sous réserve de la mention de source « Collections Musée de Préhistoire de Carnac ».

      <br/><br/><span>ARTICLE 5 – INFORMATIONS PUBLIQUES</span>

      <br/><br/>Conformément aux articles L 321-1 et L 322-1 du Code des Relations entre le Public et l'Administration, les contenus informatifs de ce site sont des informations publiques dont la réutilisation est libre, à la condition que ces dernières ne soient pas altérées, que leur sens ne soit pas dénaturé, et que leurs sources et la date de leur dernière mise à jour soient mentionnées.

      <br/><br/>Aucun transfert de propriété des informations publiques n'est opéré au profit du réutilisateur. Ce dernier bénéficie d'un droit personnel et non exclusif de réutilisation de ces informations publiques. Toutes personne réutilisant des informations publiques en violation des prescriptions susmentionnées est passible d'une amende prononcée par la Commission d'Accès aux Documents Administratifs.

      <br/><br/><span>ARTICLE 6 - LIENS</span>

      <br/><br/>Tout site public ou privé est autorisé à établir un lien vers les informations diffusées par le site <a href="https://www.collections.museedecarnac.com" target="_blank">www.collections.museedecarnac.com</a> , ou à utiliser les fils RSS des sites précités, sous réserve de :

      <br/><br/>- mentionner la source qui pointera grâce à un lien hypertexte directement sur le contenu visé,

      <br/><br/>- ne pas utiliser la technique du lien profond ("deep linking") , c’est-à-dire que les pages du site ne doivent pas être imbriquées à l'intérieur des pages d'un autre site, mais accessibles par l'ouverture d'une fenêtre,

      <br/><br/>- ne pas en faire une utilisation contraire à l'ordre public, aux bonnes mœurs, de ne pas porter atteinte aux droits des tiers et aux droits à l'image, à la propriété intellectuelle, et de ne pas en faire d'utilisation commerciale

      <br/><br/>Cette autorisation ne s'applique pas aux sites internet diffusant des informations à caractère polémique, pornographique, xénophobe ou pouvant, dans une plus large mesure porter atteinte à la sensibilité du plus grand nombre.

      <br/><br/>La Ville de Carnac se réserve le droit de demander la suppression d'un lien qu'elle estime non conforme à sa ligne éditoriale.

      <br/><br/><span>ARTICLE 7 - CREDITS DU PORTAIL</span>

      <br/><br/><span>Conception et réalisation graphique</span>

      <br/><br/>Société Agence 11H10, 11 rue du Clos Courtel, 35700 RENNES

    </p>
  </div>
</main>
