import { Component, OnInit } from '@angular/core';
import { CategorieService } from 'src/app/_services/categorie.service';
import { MetadataService } from 'src/app/_services/metadata.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  errorMessage = '';
  categories: any = [];

  constructor(private categoryService: CategorieService, private metadataService: MetadataService) { }

  ngOnInit(): void {
    if (this.metadataService) {
      this.metadataService.updateMetadata({
        title: 'Accueil',
        description: 'Accueil du site de la collection en ligne du musée de Carnac'
      });
    }
    this.getAll();
  }

  getAll(): any {
    this.categoryService.getDisplayedCategories().subscribe(
      data => {
        this.categories = data
      },
      err => {
        this.errorMessage = err.error.detail;
        console.warn("err: " , this.errorMessage );
      }
    )
  }

  rename(title: string){
    if(title.includes(' ')){
      title = title.replace(/ /g, "-").toLowerCase();
    }
    return title
  }

}
