import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// @ts-ignore
import * as grapesjs from 'grapesjs';
import 'grapesjs-preset-webpage';

@Component({
  selector: 'app-builder-page',
  templateUrl: './builder-page.component.html',
  styleUrls: ['./builder-page.component.scss'],
  encapsulation: ViewEncapsulation.None, 
})
export class BuilderPageComponent implements OnInit {
  editor: any;
  constructor() {
    
   }

  ngOnInit(): void {
    this.editor = grapesjs.init({
      container: "#editor",
      fromElement : true,
      width: "auto",
      StorageManager: false,
      plugins: ['gjs-preset-webpage'],
      pluginsOpts: {
          'gjs-preset-webpage': {
              // options
          }
      },
      blockManager: { 
      }
    });
    // Block
    this.editor.BlockManager.add('h1-block', {
      label: `<div>
      <img src="https://picsum.photos/70/70"/>
      <div class="my-label-block">Titre H1 by RP</div>
    </div>`,
      content: '<h1>Saisie ton titre</h1>',
      category: 'Agence 11h10',
      attributes: {
        title: 'Insert h1 block'
      },
      image: 'https://picsum.photos/70/70'
    });

    // Assets
    this.editor.AssetManager.add('http://img.jpg');
  }

}
