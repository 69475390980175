<main>
  <section class="section-musee-top">
    <img class="img-bandeau" src="assets/bandeau-haut-collections.png">
    <a href="/">
      <img class="logo" src="assets/logo.svg">
    </a>

    <div class="en-savoir-plus-container">
      <h2>En savoir plus</h2>
      <a class="en-savoir-plus" href="/">Accueil</a>
    </div>
  </section>

  <div class="ariane">
    <app-ariane page="En savoir plus"></app-ariane>
  </div>


  <div class="en-savoir-plus-container-page">
    <div class="ariane">
      <app-ariane page="En savoir plus"></app-ariane>
    </div>

    <p class="text-en-savoir-plus">

      <br/>

      Le Musée de Préhistoire de Carnac est une structure municipale labellisée « Musée de
      France » par le Ministère de la Culture. La loi du 4 janvier 2002 relative aux Musées de
      France leur attribue la mission permanente de rendre leurs collections accessibles au public
      le plus large et de mettre en œuvre des actions d'éducation et de diffusion visant à assurer
      l'égal accès de tous à la culture.

      <br/><br/><br/>

      La Ville de Carnac, soucieuse du rayonnement de son patrimoine, considère que la diffusion
      en ligne de notices et de photographies des collections du Musée de Préhistoire participe à
      sa promotion et à la diffusion des connaissances. Cette action s’inscrit dans le Projet
      Scientifique et Culturel du musée, validé le 14 décembre 2011 par délibération du Conseil
      Municipal et en octobre 2012 par le Service des Musées de France (Ministère de la Culture),

      <br/><br/><br/>Vous pouvez ici :

      <br/><br/><strong>- ENREGISTRER</strong> les images des collections, gratuitement et sans demande d'autorisation préalable
      <br/><br/><strong>- REUTILISER</strong> les images en fonction de la licence attribuée (Cf. mentions légales)
      <br/><br/><strong>- PARTICIPER</strong> à la mise à jour des notices (exemple : signaler une erreur, compléter une
                                              description…) en écrivant à l’adresse <a class="link" href="mailto:contact@museedecarnac.fr">contact@museedecarnac.fr</a> (mettre en objet :
                                              « collections en ligne »)

      <br/><br/><br/>Au plaisir de vous accueillir à Carnac !

    </p>
  </div>
</main>
